<template>
	<div class="homeAll"  v-loading="buyDisable">
		<div class="head">
			<img src="../../assets/logo.png" style="transform: rotate(-0deg);width: 80px;" @click="logoClick" class="logo"/>
			<div class="uname">语音转换系统</div>
			<!-- <div class="uname">源生开发体验系统</div> -->
			<!-- <div style="">可用数量：{{count}}</div> -->
		</div>
		<div class="mid">
			<div class="mid_text">
				<el-form>
					<el-form-item>
						<div style="width: 100%;height: 100%;display: flex;flex-direction: column;">
							<el-input
								v-model="text"
								:rows="10"
								placeholder="请输入文案"
								type="textarea"
							/>
							<!-- <el-select v-model="per" placeholder="选择配音">
								<el-option label="男中" value="nanzhong" />
								<el-option label="男英" value="nanying" />
								<el-option label="女中" value="nvzhong" />
								<el-option label="女英" value="nvying" />
							</el-select> -->
							
							<el-radio-group v-model="per" @change="perChange" >
								<el-radio label="nanzhong" size="large">中文-男声</el-radio>
								<el-radio label="nanying" size="large">英文-男声</el-radio>
								<el-radio label="nvzhong" size="large">中文-女声</el-radio>
								<el-radio label="nvying" size="large">英文-女声</el-radio>
							</el-radio-group>
							
						</div>
						
					</el-form-item>
				</el-form>
				<!-- <div class="slider-demo-block">
				    <span class="demonstration">音量</span>
				    <el-slider v-model="volume" />
				  </div> -->
				  <div class="slider-demo-block">
				    <span class="demonstration">音速</span>
				    <el-slider v-model="rate" />
				  </div>
			</div>
			
			
			<el-button type="primary" size="large" 
				style="width: 200px;height: 60px;font-size: 20px;font-weight: 600;" 
				v-loading.fullscreen.lock="fullscreenLoading"
				@click="confirm"
			>流式合成</el-button>
			
			
			
		</div>
		<div class="btm">
			<div class="cpinfo">
				<div style="font-size: 16px;color: #fff;">Copyright @ www.chinasinabc.com.All Rights Reserved<br/><span @click="beian">闽ICP备2022015768号-3</span></div>
			</div>
			<div class="barcode">
				<!-- <img src="../../assets/wxewm.jpg" style="max-height: 100%;"/> -->
			</div>
		</div>
	</div>
</template>

<script>
	import { doPost,doGet,doPostMore } from '@/api/apis'
	import axios from 'axios'
	
	import { ElNotification } from 'element-plus'
	
	import getMAC, { isMAC } from 'getmac'
	
	let socket;
	var count = 0
	var timeout = null
	
	// 创建一个新的AudioContext实例
	const audioContext = new AudioContext();
	
	// 创建一个队列来存储接收到的音频数据
	const audioQueue = [];
	
	let source = null;
	
	let stopPlaying = false;
	
	async function playNextAudio() {
	  while (!stopPlaying || audioQueue.length != 0) {
	    if (audioQueue.length === 0) {
	      await new Promise(resolve => setTimeout(resolve, 10));
	      continue;
	    }
	
	    // 创建一个新的AudioBufferSourceNode实例
	    source = audioContext.createBufferSource();
	
	    // 将AudioBufferSourceNode与队列中的第一个音频数据关联
	    source.buffer = audioQueue.shift();
	
	    // 将AudioBufferSourceNode连接到音频渲染设备
	    source.connect(audioContext.destination);
	
	    // 开始播放音频
	    source.start();
	
	    // 等待音频播放完毕
	    await new Promise(resolve => source.onended = resolve);
	
	    source = null;
	  }
	}
	
	export default{
		name:'',
		props:{
			// vdata:Object,
		},
		components: { },
		computed:{ },
		watch:{
			vdata:function(newData, oldData){
				
			},
			chatData:function(newData, oldData) {
				console.log("newData",newData)
			    this.$nextTick(() => {
			      const chatWindow = document.querySelector('#chat-window');
				  console.log("chatWindow",chatWindow)
			      chatWindow.scrollTop = chatWindow.scrollHeight;
				  console.log("chatWindow.scrollHeight",chatWindow.scrollHeight)
			    });
			}
		},
		data(){
			return{
				res:null,
				// 连接标志位
				lockReconnect: false,
				dialogVisible:false,
				model:"gpt-3.5-turbo",
				content:"",
				tokens:0,
				inputDisable:false,
				chatData:[
				],
				payType:1,
				user:{
					userName:"***"
				},
				wsCfg: {
					// websocket地址
					url: "wss://ts.chinasinabc.com/wss/"+this.getRandomString(8)
					// url: "ws://43.255.29.8:18892/"+this.getRandomString(8)
					// url: "ws://127.0.0.1:18892/xheeex"
				},
				socket:null,
				allModel:[],
				selected: null,
				text:"我们是新加坡、中国等亚洲国家和地区最领先的小学教育网。借助强大的互联网技术，改善传统教学模式。融入全新的教育理念。将最丰富的教学教材资源，展现给学生们共享。创造一条高速信息通道，使教师和学生们都能感受到课程的丰富和资源的精华。我们的在线辅导师，专业讲解分析完全来至新加坡名校的考卷题。我们很乐意帮助同学们，也很感谢家长们提出宝贵的建议和意见。",
				per:"nvzhong",
				fullscreenLoading:false,
				count:0,
				dialogFormVisible:false,
				formLabelWidth:'80px',
				goodsQty:1,
				inOrder:false,
				codeImg:null,
				loding:false,
				outTradeNo:null,
				buyDisable:false,
				volume:50,
				rate:63,
				code:0
			}
		},
		methods:{
			
			createWebSocket() {
				var mvue = this
				try {
					// 创建Web Socket 连接
					socket = new WebSocket(this.wsCfg.url);
					socket.binaryType = 'arraybuffer';
					this.socket = socket
					// 初始化事件
					this.initEventHandle(socket);
				} catch (e) {
					// 出错时重新连接
					console.log("e",e)
					this.reconnect(this.wsCfg.url);
				}
			},
			initEventHandle(socket) {
				var mvue = this
				// 连接关闭时触发
				socket.onclose = (err) => {
					console.log("连接关闭",err);
				};
				// 通信发生错误时触发
				socket.onerror = (e) => {
					// 重新创建长连接
					console.log("onerrre",e)
					count++
					if(count > 3){
						clearTimeout(timeout)
					}else{
						this.reconnect();
					}
					
				};
				// 连接建立时触发
				socket.onopen = () => {
					console.log("连接成功");
				};
				// 客户端接收服务端数据时触发
				socket.onmessage = async (event)  => {
					// 业务逻辑处理
					console.log(event.data, "ws:data");
					
					// 检查数据是否为JSON格式的用户信息数据
					if (typeof event.data === 'string') {
						// try {
						// 	const userInfo = JSON.parse(event.data);
						// 	console.log(userInfo);
							if(event.data === "end"){
								stopPlaying = true;
							}
							return;
						// } catch (error) {
						// 	console.error('Received data is not JSON:', error);
						// }
						
					}


					if(mvue.code == 0){
						// 使用AudioContext的decodeAudioData方法解码音频数据
						const audioBuffer = await audioContext.decodeAudioData(event.data);
						
						// 将解码后的音频数据添加到队列中
						audioQueue.push(audioBuffer);
						
						// 如果当前没有音频正在播放，则开始播放队列中的第一个音频
						if (!source) {
							playNextAudio();
						}
					}else if(mvue.code == 1){
						// 处理字节流
						const data = new Blob([event.data]);
					
						// 创建一个指向该 Blob 的 URL
						const url = window.URL.createObjectURL(data);
					
						// 创建一个隐藏的 <a> 元素
						const a = document.createElement('a');
						a.style.display = 'none';
						document.body.appendChild(a);
					
						// 设置其 href 属性为该 URL
						a.href = url;
						a.download = new Date().getTime()+'.mp3'; // 设置你想要的文件名
					
						// 模拟点击该元素以在浏览器中下载文件
						a.click();
					
						// 清理
						window.URL.revokeObjectURL(url);
						document.body.removeChild(a);
					}
					

				};
			},
			reconnect() {
				if (this.lockReconnect) {
					return;
				}
				this.lockReconnect = true;
				
				// 没连接上会一直重连，设置延迟避免请求过多
				timeout = setTimeout(() => {
					console.log("recnot timeout")
					this.lockReconnect = false;
					this.createWebSocket(this.wsCfg.url);
				}, 2000);
			},
			
			
			test() {
				// 给服务器发送一个字符串:
				// ws.send("Hello!");
				console.log("test...")
				this.getData()
				// socket.send("Hello!");
				// this.$router.push("/create")
			},
			fetchData: async function() {
				var mvue = this
			  //console.log("fetchdata",this.flowmodeid)
			    this.listLoading = true
				
				
				
			    await doPost({loginname: "xxx", name: "", pwd: "123123@Wwe", password2: "123123@Wwe", captcha: "h90x"},mvue.serverurl+'/user/addFitUser').then(response => {
					mvue.res = response
			       console.log("doPost succes",response)
			    }).catch(error => {
			      console.log("doPost error",error)
			    })
				// axios({
				//   url: mvue.apipre+'/procurement/flow/getFlow',
				//   method: 'post',
				//   data:{flowmodeid:1 }
				// })
				// .then(function(res){
				// 	mvue.res = res
				// 	     console.log("doPost succes",res)
				// })
				// .catch(function(res){
				// 	console.log("err",res)
				// })
			},
			getRandomString(length) {
			    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			    var result = '';
			    for (var i = 0; i < length; i++) {
			        result += chars.charAt(Math.floor(Math.random() * chars.length));
			    }
				console.log("result",result)
			    return result;
				
			},
			getData : async function(){
			  await this.fetchData();
			  console.log("res",this.res)
			},
			
			getFile(){
				var mvue = this
				doGet({},mvue.apipre+'/getFile').then(response => {
					mvue.allModel = response
				   console.log("doPost succes",response)
				}).catch(error => {
				  console.log("doPost error",error)
				})
			},
			checkm(){
				var mvue = this
				doGet({},mvue.apipre+'/getM').then(response => {
					mvue.count = response.count
				   console.log("doPost succes",response)
				}).catch(error => {
				  console.log("doPost error",error)
				})
			},
			
			checkImg(item){
				if(item == null){
					return false
				}
				var up = item.toUpperCase()
				if(up.endsWith(".PNG") || up.endsWith(".JPG") || up.endsWith(".JPEG")){
					return true
				}else{
					return false
				}
			},
			getImgUrl(item){
				return this.apipre +"/files/"+item
			},
			getVideoSrc(item){
				return this.apipre +"/files/"+item
			},
			isImg(item){
				console.log("item",item)
				return item.endsWith(".png") || item.endsWith(".jpg") || item.endsWith(".jpeg")
			},
			
			getTime(){
				let date = new Date();
				let formattedDate = date.getFullYear() + '_' + 
				    ('0' + (date.getMonth() + 1)).slice(-2) + '_' + 
				    ('0' + date.getDate()).slice(-2) + '_' + 
				    ('0' + date.getHours()).slice(-2) + '.' + 
				    ('0' + date.getMinutes()).slice(-2) + '.' + 
				    ('0' + date.getSeconds()).slice(-2);
				return (formattedDate);
			},
			perChange(val){
				if(val === "nanzhong" || val === "nvzhong"){
					this.text = "我们是新加坡、中国等亚洲国家和地区最领先的小学教育网。借助强大的互联网技术，改善传统教学模式。融入全新的教育理念。将最丰富的教学教材资源，展现给学生们共享。创造一条高速信息通道，使教师和学生们都能感受到课程的丰富和资源的精华。我们的在线辅导师，专业讲解分析完全来至新加坡名校的考卷题。我们很乐意帮助同学们，也很感谢家长们提出宝贵的建议和意见。"
				}else{
					this.text = "We are the most leading primary education website in Singapore, China and others Asian countries. With advance Internet Technology support, we improve the traditional teaching model and integration of new educational philosophy. we also share the most abundant learning resources with students and create a high-speed information channel for students and teachers to get hold of essence of our curriculum and resources.Our online Guidance teachers in, professional analysis entirely explain to Singapore famous schools exam questions.We are very happy to help the students and also very grateful to the parents for their valuable suggestions and comments."
				}
			},
			logoClick(){
				window.location.href = "https://www.chinasinabc.com"
			},
			confirm(){
				var mvue = this
				
				// mvue.dialogFormVisible = true;
				// return;
				
				// var mac = getMAC()
				var resPath = this.getTime()
				
				
				// mvue.fullscreenLoading = true
				// doGet({},mvue.apipre+'/tranAudioNew?resPath='+resPath
				// 	+"&model="+mvue.selected+"&text="+mvue.text+"&per="+mvue.per).then(response => {
				//    console.log("doPost succes",response)
				//    mvue.fullscreenLoading = false
				   
				//    mvue.count = response.count
				//    // const link = document.createElement('a');
				//    // link.download = 'res.mp4';
				//    // link.href = mvue.apipre+"/getResFile/"+response.file;
				//    // link.click();
				   
				//    var music = new Audio( mvue.apipre+"/getResFile/"+response.file);
				//    music.play();
				//    // window.location.href = mvue.apipre+"/files/"+response;
				// }).catch(error => {
				//   console.log("doPost error",error)
				//   mvue.fullscreenLoading = false
				// })
				
				stopPlaying = false;
				source = null;
				
				
				var param = {
					code:0,
					text:mvue.text,
					per:mvue.per,
					rate:(this.rate-50) +"%",
					volume:(this.volume - 50) + "%",
				}
				mvue.code = 0
				
				if(this.rate >= 50){
					param.rate = "+" +param.rate
				}
				if(this.volume >= 50){
					param.volume = "+" +param.volume
				}
				
				this.socket.send(JSON.stringify(param))
				
				// console.log("param",param)
				
				
			},
			
			
			confirm1(){
				var mvue = this
				
				var resPath = this.getTime()
				
				stopPlaying = false;
				source = null;
				
				var param = {
					code:1,
					text:mvue.text,
					per:mvue.per,
					rate:(this.rate-50) +"%",
					volume:(this.volume - 50) + "%",
				}
				mvue.code = 1
				
				if(this.rate >= 50){
					param.rate = "+" +param.rate
				}
				if(this.volume >= 50){
					param.volume = "+" +param.volume
				}
				
				this.socket.send(JSON.stringify(param))
				
				
			},
			
			
			buy(){
				var mvue = this
				
				mvue.buyDisable = true
				mvue.loding = true
				doGet({},mvue.payapipre+'/addAiTalkOrder?goodsQty='+mvue.goodsQty).then(response => {
					mvue.inOrder = true
					mvue.codeImg = mvue.payapipre+"/"+ response.data.codeImg
					mvue.outTradeNo = response.data.outTradeNo
				    console.log("doPost succes",response)
				    mvue.loding = false
				   
				   
				    // 总的执行5次
				    let total = 30;
				    // 每5秒执行一次
				    let delay = 1000 * 2;
				    // 执行计数
				    let count = 0;
				   
				    let intervalID = setInterval(() => {
				        count++;

						// 执行5次后清除定时器
						if (count >= total) {
							mvue.buyDisable = false
							clearInterval(intervalID);
							mvue.dialogFormVisible = false
						}else{
							doGet({},mvue.payapipre+'/getOrder?outTradeNo='+mvue.outTradeNo).then(response => {
								
								if(response.data.status == 1){
									doGet({},mvue.apipre+'/addM?outTradeNo='+mvue.outTradeNo).then(response => {
										mvue.count = response.count
									    console.log("doPost succes",response)
									}).catch(error => {
									  console.log("doPost error",error)
									})
									mvue.buyDisable = false
									clearInterval(intervalID);
									mvue.dialogFormVisible = false
								}
								
							}).catch(error => {
							  console.log("doPost error",error)
							  mvue.buyDisable = false
							  clearInterval(intervalID);
							  mvue.dialogFormVisible = false
							})
						}
						
				    }, delay);
				   
				   
				}).catch(error => {
				  console.log("doPost error",error)
				  mvue.loding = false
				  mvue.buyDisable = false
				})
			},
			getBarcode(){
				return this.payserver + "/"+this.codeImg
			},
			beian(){
				window.location.href = "https://beian.miit.gov.cn/"
			}
		},
		mounted() {
			// console.log("this.$route.query.user",this.$route.query.user)
			// console.log("decodeURIComponent(this.$route.query.user)",decodeURIComponent(this.$route.query.user))
			// console.log("JSON.parse(decodeURIComponent(this.$route.query.user))",JSON.parse(decodeURIComponent(this.$route.query.user)))
			// var par = JSON.parse(this.$route.query.user)//decodeURIComponent(this.$route.query.row)
			// this.user = JSON.parse(decodeURIComponent(this.$route.query.user))
			
			
			
			// console.log("user",this.user)
			// this.getData()
			
			var userStr =  localStorage["gptuser"]
			if(userStr != null){
				this.user = JSON.parse(userStr)
			}
			// console.log("user",this.user)
			
			// this.wsCfg.url = this.wsCfg.url+"/"+this.user.phone
			this.createWebSocket();
			
			// this.getFile()
			// this.checkm()
		}
	}
		
</script>

<style lang="scss" scoped="scoped">
	
	::-webkit-scrollbar {
	  -webkit-appearance: none;
	  width: 8px;
	  height: 2px;
	}
	::-webkit-scrollbar-track {
	  background: rgba(0, 0, 0, 0.1);
	  border-radius: 0;
	}
	::-webkit-scrollbar-thumb {
	  cursor: pointer;
	  border-radius: 5px;
	  background: rgba(0, 0, 0, 0.1);
	  transition: color 0.2s ease;
	}
	::-webkit-scrollbar-thumb:hover {
	  background: rgba(0, 0, 0, 0.1);
	}

	.homeAll{
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		.head{
			height: 15%;
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			text-align: center;
			position: relative;
			.logo{
				max-height: 150px;transform: rotate(-90deg);position: absolute;
				left: 25px;
				top: 5px;
			}
			.uname{
				height: 100%;width: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				font-size: 28px;
				font-weight: 600;
				color:#000000
			}
			.logout{
				height: 100%;width: 10%;
				margin-left: auto;margin-right: 0px;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				position: absolute;
				right: 0px;
				.logout_left{
					
				}
				.logout_right{
					background-color: #e1e1e1;max-height: 80%;
					position:absolute;
					right: 0px;
				}
				
			}
		}
		.mid{
			height: 75%;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			// justify-content: center;
			text-align: center;
			
			position: relative;
			
			.mid_img{
				width: 80%;
				height: 500px;
				// background-color: #55557f;
				
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				// align-items: center;
				// justify-content: center;
				// text-align: center;
				overflow-x: hidden;
				overflow-y: scroll;
				
				.mid_img_item{
					width: 200px; height: 200px;display: flex;align-items: center;justify-content: center;
					background-color: #adadad;
				}
			}
			.mid_text{
				width: 80%;
				height: 60%;
				margin-top: 20px;
				// background-color: #55ffff;
			}
		}
		.btm{
			height: 10%;
			width: 100%;
			background-color: #0049dd;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			text-align: center;
			
			position: relative;
			
			.cpinfo{
				height: 100%;width: 30%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				text-align: center;
			}
			.barcode{
				height: 100%;width: 10%;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				position: absolute;
				right: 10px;
			}
		}
	}
</style>
